import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@bw/layouts"
import { Seo, Button } from "@bw/bits"
import { SidebarLayout, Subpages } from "@bw/partials"
import { useTranslation } from "react-i18next"
import * as modules from "@bw/modules/resolvers"

const PageTemplate = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { page, parents, siblings, children } = data

  if (typeof page === "undefined") {
    return null
  }

  /*
  const breadcrumbsLinks = [
    {
      title: t("homepage"),
      to: "/",
    },
  ]

  if (parents?.nodes) {
    parents.nodes.forEach(bread => breadcrumbsLinks.push(bread))
  }

  breadcrumbsLinks.push({
    title: page.title,
    to: page.url,
  })
  */

  /*
   * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
   */
  const contents = page.content
    .filter(module => module !== null)
    .map((module, i) => {
      const LoadedModule = modules[module.__typename.replace("Module", "")]
      if (typeof LoadedModule === "undefined") {
        return null
      }

      if (module.buttonType?.value === "url" && module.buttonUrl) {
        module.button = (
          <Button
            label={module.buttonText}
            href={module.buttonUrl}
            external={module.buttonIsExternal}
            primary
          />
        )
      }

      if (module.buttonType?.value === "entry" && module.buttonEntry) {
        module.button = (
          <Button
            label={module.buttonText}
            to={module.buttonEntry.url}
            primary
          />
        )
      }

      module.buttonPosition = module.buttonPosition?.value

      return <LoadedModule key={i} {...module} />
    })

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      {/*
       * Loading the Header module, ex Hero or PageHeader
       */}
      {page.header
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          if (!module.title && !pageContext.isHomePage) {
            module.title = page.title
          }

          /*
          if (module.__typename === "PageHeaderModule") {
            module.breadcrumbs = breadcrumbsLinks
          }
          */

          return <LoadedModule key={i} {...module} />
        })}

      {pageContext.hasSiblings && <Subpages tabs={siblings.nodes} />}

      {pageContext.hasChildren &&
      children.nodes &&
      children.nodes.length > 0 ? (
        <SidebarLayout sidemenu={children.nodes}>{contents}</SidebarLayout>
      ) : (
        contents
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery(
    $id: String!
    $siblings: [String]
    $hasSiblings: Boolean = false
    $parents: [String]
    $hasParents: Boolean = false
    $children: [String]
    $hasChildren: Boolean = false
  ) {
    parents: allCollectionPages(filter: { id: { in: $parents } }) {
      nodes @include(if: $hasParents) {
        to: slug
        title
      }
    }

    siblings: allCollectionPages(filter: { id: { in: $siblings } }) {
      nodes @include(if: $hasSiblings) {
        to: slug
        title
      }
    }

    children: allCollectionPages(filter: { id: { in: $children } }) {
      nodes @include(if: $hasChildren) {
        to: slug
        title
      }
    }

    page: collectionPages(id: { eq: $id }) {
      url
      title
      metaTitle
      metaDescription
      image {
        url
      }
      doNotIndex

      teaserImage {
        url
      }
      header {
        __typename
        suptitle
        title
        subtitle

        ...HeroModuleFragment
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ...CallToActionModuleFragment
        ...ContentModuleFragment
        ...ContentWithImageModuleFragment
        ...GalleryModuleFragment
        ...ContentOnColumnsModuleFragment
        ...DownloadsModuleFragment
        ...TeasersLatestModuleFragment
        ...TeasersCustomModuleFragment
        ...TeasersManualModuleFragment
        ...LogoGridModuleFragment
        ...ImageModuleFragment
        ...ImagesLinksModuleFragment
        ...VideoModuleFragment
        ...FormModuleFragment
        ...CollectionModuleFragment
      }
    }
  }
`
